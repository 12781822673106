
import CompanyStructure from "~/graphql/Company/CompanyStructure.gql";

export default {
  apollo: {
    companyStructure: {
      query: CompanyStructure,
      variables() {
        return {
          depth: 1,
          cvrNumber: this.cvr,
        };
      },
    },
  },

  props: {
    cvr: {
      type: Number,
      required: true,
    },
  },

  computed: {
    rOwners() {
      if (this.companyStructure) {
        return this.companyStructure.realOwners.map((o) => {
          let owner = {};
          owner.type = "PERSON";
          owner.id = o.entityId;
          owner.name = o.name;
          owner.share = o.share ? o.share : o.shareByVotes;
          return owner;
        });
      }
      return [];
    },
    foreignOwningCompanies() {
      if (this.companyStructure) {
        return this.companyStructure.foreignOwningCompanies.map((c) => {
          let owner = {};
          owner.type = "COMPANY";
          owner.id = c.id;
          owner.name = c.name;
          owner.share = c.share;
          return owner;
        });
      }
      return [];
    },
    owningCompanies() {
      if (this.companyStructure) {
        return this.companyStructure.owningCompanies.map((c) => {
          let owner = {};
          owner.type = "COMPANY";
          owner.id = c.company.id;
          owner.name = c.company.name;
          owner.share = c.share;
          owner.cvr = c.company.cvrNumber;
          return owner;
        });
      }
      return [];
    },
  },
};
