

export default {
  props: {
    bfeNumber: {
      type: Number,
      required: true,
    },
    municipality: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getStaticSources() {
      return [
        {
          typeText: "Matriklen",
          link: `https://www.matriklen.dk/#/kort/sfe/${this.bfeNumber}`,
        },
        {
          typeText: "OIS",
          link: `https://ois.dk/search/${this.bfeNumber}`,
        },
        {
          typeText: "GI Selvbetjening",
          link: `https://selvbetjening.gi.dk/`,
        },
        {
          typeText: "Weblager",
          link: `https://weblager.dk/`,
        },
      ]
    },
  },
};
