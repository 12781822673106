import { render, staticRenderFns } from "./TablePlans.vue?vue&type=template&id=776596ce"
import script from "./TablePlans.vue?vue&type=script&lang=js"
export * from "./TablePlans.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default,ChromeTooltip: require('/app/components/Chrome/ChromeTooltip.vue').default,SimpleTable: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTable.vue').default})
