import "core-js/modules/es6.array.map.js";
import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import PropertyWidgetsProperty from "~/graphql/Property/PropertyWidgetsProperty.gql";
import PropertyWidgetsTenants from "~/graphql/Property/PropertyWidgetsTenants.gql";
import OverviewReferenceProperties from "~/graphql/Property/OverviewReferenceProperties.gql";
import { getPropertyCentroid, hasPropertyCentroid, mapPropertyAnalysisRequirementCodes } from "~/helpers/property-helpers";
import { calculateBuildingArea, calculateRegisteredArea } from "~/helpers/area-calculation-helpers.js";
import { getLatestTransactions, getLatestTransaction } from "~/helpers/transaction-helpers.js";
import { getLatestValuation } from "~/helpers/valuation-helpers.js";
import { getPlots, getBuildings, getUnits, getTechnicalFacilities } from "~/helpers/property-helpers.js";
import { getUsageBreakdownByArea } from "~/helpers/usage-helpers.js";
import { getTenantBreakdownByEmployees } from "~/helpers/tenant-helpers.js";
import WidgetLayout from "~/components/Widget/WidgetLayout.vue";

// import WidgetLayoutRowCellSingle from "~/components/Widget/WidgetLayoutRowCellSingle.vue";
import WidgetLayoutRowCellDouble from "~/components/Widget/WidgetLayoutRowCellDouble.vue";
import WidgetLayoutRowCellThirds from "~/components/Widget/WidgetLayoutRowCellThirds.vue";
import WidgetKeyValue from "~/components/Widget/WidgetKeyValue.vue";
import WidgetTable from "~/components/Widget/WidgetTable.vue";
import WidgetBlank from "~/components/Widget/WidgetBlank.vue";
import TablePlans from "./components/TablePlans.vue";
import TableReferenceProperties from "./components/TableReferenceProperties.vue";
import WidgetTransactions from "./components/WidgetTransactions.vue";
import WidgetLayoutRowSpacer from "~/components/Widget/WidgetLayoutRowSpacer.vue";
import { filter as _filter } from "~/composables/useFilter";
import CondoTenants from "~/pages/explore/property/_id/components/condo/CondoTenants.vue";
import PropertyTenants from "~/pages/explore/property/_id/components/mainproperty/PropertyTenants.vue";
import PropertyCompanies from "~/pages/explore/property/_id/components/mainproperty/PropertyCompanies.vue";
import TemplateDocument from "~/pages/template-builder/components/Templates/TemplateDocument.vue";
import TableSources from "~/pages/explore/property/_id/components/mainproperty/components/TableSources.vue";
export default {
  components: {
    TemplateDocument: TemplateDocument,
    PropertyCompanies: PropertyCompanies,
    PropertyTenants: PropertyTenants,
    CondoTenants: CondoTenants,
    WidgetLayout: WidgetLayout,
    // WidgetLayoutRowCellSingle,
    WidgetLayoutRowCellDouble: WidgetLayoutRowCellDouble,
    WidgetLayoutRowCellThirds: WidgetLayoutRowCellThirds,
    WidgetKeyValue: WidgetKeyValue,
    WidgetTable: WidgetTable,
    WidgetBlank: WidgetBlank,
    WidgetTransactions: WidgetTransactions,
    TablePlans: TablePlans,
    TableReferenceProperties: TableReferenceProperties,
    WidgetLayoutRowSpacer: WidgetLayoutRowSpacer,
    TableSources: TableSources
  },
  apollo: {
    property: {
      query: PropertyWidgetsProperty,
      variables: function variables() {
        return {
          bfeNumber: parseInt(this.$route.params.id, 10)
        };
      },
      update: function update(data) {
        return data.propertyByBFENumber;
      },
      error: function error(_error) {
        console.error("PropertyOverview failed:", _error);
      }
    },
    tenants: {
      query: PropertyWidgetsTenants,
      variables: function variables() {
        var _this$property;
        return {
          plotNumbers: (_this$property = this.property) === null || _this$property === void 0 || (_this$property = _this$property.plots) === null || _this$property === void 0 ? void 0 : _this$property.map(function (p) {
            return p === null || p === void 0 ? void 0 : p.plotNumber;
          })
        };
      },
      skip: function skip() {
        return !this.property;
      },
      update: function update(data) {
        return data.addressesForPlotNumbers;
      },
      error: function error(_error2) {
        console.error("PropertyOverview failed:", _error2);
      }
    },
    overviewReferenceProperties: {
      query: OverviewReferenceProperties,
      variables: function variables() {
        var propertyCentroid = getPropertyCentroid(this.property);
        return {
          filter: {
            limit: 5,
            radius: 1000,
            offset: 0,
            lat: propertyCentroid.lat,
            lng: propertyCentroid.lng,
            orderBy: "takeover_date",
            orderDirection: "DESC"
          }
        };
      },
      update: function update(data) {
        return data.findReferenceProperties;
      },
      skip: function skip() {
        return !this.property && !this.hasPropertyCentroid(this.property);
      },
      error: function error(_error3) {
        console.error("PropertyOverview failed:", _error3);
      }
    }
  },
  computed: {
    filter: function filter() {
      return _filter;
    },
    loading: function loading() {
      return this.$apollo.queries.property.loading;
    },
    propertyValuation: function propertyValuation() {
      var _this$getLatestValuat;
      return this.property && ((_this$getLatestValuat = this.getLatestValuation(this.property)) === null || _this$getLatestValuat === void 0 ? void 0 : _this$getLatestValuat.propertyValuation) || "";
    },
    lastUpdated: function lastUpdated() {
      var _this$getLatestValuat2;
      return this.property && ((_this$getLatestValuat2 = this.getLatestValuation(this.property)) === null || _this$getLatestValuat2 === void 0 ? void 0 : _this$getLatestValuat2.lastUpdated) || "";
    },
    landValuation: function landValuation() {
      var _this$getLatestValuat3;
      return this.property && ((_this$getLatestValuat3 = this.getLatestValuation(this.property)) === null || _this$getLatestValuat3 === void 0 ? void 0 : _this$getLatestValuat3.landValuation) || "";
    },
    handoverDate: function handoverDate() {
      var _this$getLatestTransa;
      return this.property && ((_this$getLatestTransa = this.getLatestTransaction(this.property)) === null || _this$getLatestTransa === void 0 ? void 0 : _this$getLatestTransa.handoverDate) || "";
    },
    transferType: function transferType() {
      var _this$getLatestTransa2;
      return this.property && ((_this$getLatestTransa2 = this.getLatestTransaction(this.property)) === null || _this$getLatestTransa2 === void 0 ? void 0 : _this$getLatestTransa2.transferType) || "";
    },
    transactionInfoTotalPurchaseSum: function transactionInfoTotalPurchaseSum() {
      var _this$getLatestTransa3;
      return this.property && ((_this$getLatestTransa3 = this.getLatestTransaction(this.property)) === null || _this$getLatestTransa3 === void 0 || (_this$getLatestTransa3 = _this$getLatestTransa3.transactionInfo) === null || _this$getLatestTransa3 === void 0 ? void 0 : _this$getLatestTransa3.totalPurchaseSum) || 0;
    },
    transactionInfoOwnerChanges: function transactionInfoOwnerChanges() {
      var _this$getLatestTransa4;
      return this.property && ((_this$getLatestTransa4 = this.getLatestTransaction(this.property)) === null || _this$getLatestTransa4 === void 0 || (_this$getLatestTransa4 = _this$getLatestTransa4.transactionInfo) === null || _this$getLatestTransa4 === void 0 || (_this$getLatestTransa4 = _this$getLatestTransa4.ownerChanges) === null || _this$getLatestTransa4 === void 0 ? void 0 : _this$getLatestTransa4.length) - 1 || 0;
    },
    plans: function plans() {
      return this.property && this.property.plots.flatMap(function (p) {
        var _p$geometry;
        return p === null || p === void 0 || (_p$geometry = p.geometry) === null || _p$geometry === void 0 ? void 0 : _p$geometry.plans;
      }) || [];
    },
    municipality: function municipality() {
      var _this$property$plots$;
      var code = ((_this$property$plots$ = this.property.plots[0].addresses) === null || _this$property$plots$ === void 0 || (_this$property$plots$ = _this$property$plots$[0]) === null || _this$property$plots$ === void 0 ? void 0 : _this$property$plots$.municipalityCode) || null;
      if (code) {
        return Number(code);
      }
      return null;
    },
    isProperty: function isProperty() {
      var _this$property2;
      return ((_this$property2 = this.property) === null || _this$property2 === void 0 ? void 0 : _this$property2.plots) != null;
    },
    propertyPlotNumbers: function propertyPlotNumbers() {
      var _this$property$plots;
      return ((_this$property$plots = this.property.plots) === null || _this$property$plots === void 0 || (_this$property$plots = _this$property$plots.filter(function (plot) {
        return plot != null;
      })) === null || _this$property$plots === void 0 ? void 0 : _this$property$plots.map(function (x) {
        return x.plotNumber;
      })) || [];
    },
    energyLabel: function energyLabel() {
      var energyLabels = this.property.plots.flatMap(function (p) {
        return p.buildings;
      }).filter(function (b) {
        return b && b.energyLabel;
      }).map(function (b) {
        return b.energyLabel;
      }).sort(function (a, b) {
        return new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime();
      });
      if (energyLabels.length === 0) {
        return {
          classification: "",
          validTo: null,
          validFrom: null
        };
      }
      return energyLabels[0];
    },
    analysisRequirementDescription: function analysisRequirementDescription() {
      return mapPropertyAnalysisRequirementCodes(this.property);
    },
    chartDataFordeling: function chartDataFordeling() {
      var r = {
        labels: [],
        series: []
      };
      var tenantBreakdownByEmployees = this.getTenantBreakdownByEmployees(this.tenants).filter(function (t) {
        return t.employeeCount > 0;
      });
      var topFiveTentantsByEmployees = tenantBreakdownByEmployees.sort(function (a, b) {
        return b.employeeCount - a.employeeCount;
      }).slice(0, 5);
      var remainingTenants = tenantBreakdownByEmployees.filter(function (t) {
        return !topFiveTentantsByEmployees.includes(t);
      });
      if (tenantBreakdownByEmployees) {
        topFiveTentantsByEmployees.forEach(function (tenant) {
          r.labels.push(tenant.companyName);
          r.series.push(Math.floor(tenant.employeeCount));
        });
        if (remainingTenants.length > 0) {
          r.labels.push("Andet");
          r.series.push(Math.floor(remainingTenants.reduce(function (acc, t) {
            return acc + t.employeeCount;
          }, 0)));
        }
      }
      if (r.labels.length > 0) {
        return r;
      } else {
        return undefined;
      }
    },
    chartDataAnvendelse: function chartDataAnvendelse() {
      var r = {
        labels: [],
        series: []
      };
      var maxUsageTypes = 6;
      var usageBreakdownByArea = this.getUsageBreakdownByArea(this.property, maxUsageTypes, this.$t("USAGE_TYPE_OTHER"));
      if (usageBreakdownByArea) {
        Object.keys(usageBreakdownByArea).map(function (key) {
          return usageBreakdownByArea[key];
        }).forEach(function (value) {
          r.labels.push(value.usageText);
          r.series.push(Math.floor(value.usageArea));
        });
      }
      if (r.labels.length > 0) {
        return r;
      } else {
        return undefined;
      }
    },
    referencePropertyHits: function referencePropertyHits() {
      var _this$overviewReferen;
      return ((_this$overviewReferen = this.overviewReferenceProperties) === null || _this$overviewReferen === void 0 || (_this$overviewReferen = _this$overviewReferen.hits) === null || _this$overviewReferen === void 0 ? void 0 : _this$overviewReferen.filter(function (h) {
        return h && h.property != null;
      })) || [];
    }
  },
  methods: {
    calculateBuildingArea: calculateBuildingArea,
    calculateRegisteredArea: calculateRegisteredArea,
    getLatestTransactions: getLatestTransactions,
    getLatestTransaction: getLatestTransaction,
    getLatestValuation: getLatestValuation,
    getPlots: getPlots,
    getBuildings: getBuildings,
    getUnits: getUnits,
    getTechnicalFacilities: getTechnicalFacilities,
    getUsageBreakdownByArea: getUsageBreakdownByArea,
    getTenantBreakdownByEmployees: getTenantBreakdownByEmployees,
    getPropertyCentroid: getPropertyCentroid,
    hasPropertyCentroid: hasPropertyCentroid,
    shortDate: function shortDate(string) {
      return this.$options.filters.shortdate(string);
    },
    longDate: function longDate(string) {
      return this.$options.filters.longdate(string);
    },
    navigateTo: function navigateTo(path, query) {
      this.$amplitude.track({
        event_type: "nagivate from: overview-dashboard, to: ".concat(path)
      });
      this.$router.push({
        name: path,
        query: query || {},
        params: {
          id: this.$route.params.id
        }
      });
    },
    navigateToExternal: function navigateToExternal(url) {
      this.$amplitude.track({
        event_type: "nagivate from: overview-dashboard, to: ".concat(url)
      });
      window.open(url, "_blank");
    }
  }
};